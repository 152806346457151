@import "./_variables.scss";

#fp-ig1 {
  margin: 0;
  z-index: 1;
  background-color: #69a5d8;
  padding: 15px 30px;
  min-height: 370px;

  @media #{$bootstrap-break-lg} {
    min-height: 400px;
  }

  @media #{$bootstrap-break-md} {
    min-height: 430px;
  }

  @media #{$bootstrap-break-sm} {
    min-height: 450px;
  }

  @media screen and (max-width: 595px) {
    min-height: 550px;
  }

  @media #{$bootstrap-break-xxs} {
    min-height: 600px;
  }


  div {
    position: relative;
    z-index: 9;
  }

}

#fp-title {
  padding: 0 60px;
  margin: 0 0 15px 0;
  display: table;

  @media #{$bootstrap-break-lg} {
    padding: 0 30px;
  }

  @media #{$bootstrap-break-md} {
      padding: 0;
  }

  img {
    float: left;
    margin: 0 30px 0 0;
    width: 120px; height: 120px;

    @media #{$bootstrap-break-lg} {
        width: 100px; height: 100px;
    }

    @media #{$bootstrap-break-sm} {
      width: 70px; height: 70px;
      margin-right: 15px;
    }
  }

  h1 {
    font-size: 380%;
    color: black;
    margin: 0;
    display: table-cell;
    vertical-align: middle;

    @media #{$bootstrap-break-lg} {
        font-size: 310%;
    }
    @media #{$bootstrap-break-sm} {
      font-size: 230%;
    }
    @media #{$bootstrap-break-xs} {
      font-size: 200%;
    }
    @media screen and (max-width: 440px) {
      font-size: 180%;
    }
    @media screen and (max-width: 405px) {
      font-size: 160%;
    }
    @media #{$bootstrap-break-xxs} {
      font-size: 150%;
    }
  }

}

#fp-ig2 {
  top: -220px;
  margin-bottom: -220px;
  display: block;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 390px) {
    margin-bottom: -200px;
    top: -200px;
  }

  img {
    z-index: 1;
    width: 100%;
  }

  div {
    display: block;
    position: sticky;
    bottom: 40px;
    text-align: center;

    svg {
      color: white;
      font-size: 300%;
      cursor: pointer;
    }

  }
}

.fp-btn-block {
  margin-top: 20px;
  z-index: 10;
  position: relative;

  column-count: 2;
  padding: 0 60px;
  column-gap: 50px;

  @media #{$bootstrap-break-lg} {
    padding: 0 30px;
    column-gap: 20px;
  }

  @media #{$bootstrap-break-md} {
    padding: 0;
    column-gap: 10px;
  }

  @media screen and (max-width: 595px) {
    column-count: 1;
  }

  a {
    display: table;
    margin-bottom: 15px;
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
    }
  }

  button {
    float: left;
    margin-right: 20px;
    min-width: 105px;

    @media #{$bootstrap-break-sm} {
      margin-right: 10px;
    }
  }

  h4 {
    margin: 0;
    display: table-cell;
    vertical-align: middle;

    @media #{$bootstrap-break-lg} {
      font-size: 120%;
    }

    @media #{$bootstrap-break-md} {
      font-size: 110%;
    }

    @media #{$bootstrap-break-sm} {
      font-size: 100%;
    }
  }
}

.fp-carousel {
  margin: 0 0 20px 0;
  height: 220px;
  background-color: $izele-light-green-background;
  padding: 5px 30px 0 24px;
  text-align: justify;
  text-justify: inter-word;

  .title {
    width: 100%;
    text-align: center;
    margin: 8px 0 14px 0;
    font-weight: bold;
  }

  div {
    margin: 0;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    padding: 0;
    width: 280px;

    &.block_2 {
      @media #{$bootstrap-break-sm} {
        display: none;
      }
    }

    &.block_3 {
      @media #{$bootstrap-break-md} {
        display: none;
      }
    }

    @media #{$bootstrap-break-sm} {
      display: block;
      margin: 0 auto;
    }
  }

}

.fp-carousel-item {
  position: relative;
  display: block;
  width: 286px;
  height: 150px;
  padding-top: 4px;
  box-shadow: 0 0 6px 1px rgba(0,0,0,0.75);

  &:hover {
    box-shadow: 0 0 9px 1px rgba(0,0,0,0.75);
    text-decoration: none;
  }

  @media #{$bootstrap-break-xxs} {
    width: 243px;
  }

  img {
    float:left;
    max-width: 29%;
    max-height: 65px;
    margin: 0 4px 0 4px;
  }

  .item-title {
    float: left;
    width: 67%;
    max-height: 80px;
    overflow: hidden;
  }

  h4 {
    color: white;
    margin: 0;
    text-align: left;
    text-shadow: 0 0 2px black;
  }

  div:last-child {
    background-color: rgba(255, 255, 255, .7);
    height: 75px;
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    padding: 2px 4px 2px 4px;
    text-align: left;
    line-height: 18px;
    overflow: hidden;
    color: black;
  }
}

.fp-link-block {
  display: flex;
  justify-content: space-around;

  a {
    color: #251F38;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      float: left;
      font-size: 450%;
      margin-right: 10px;
    }

    h3 {
      float: left;
      margin: 0;
      font-size: 170%;
    }

    @media #{$bootstrap-break-sm} {
      svg {
        font-size: 300%;
        margin-right: 5px;
      }

      h3 {
        font-size: 140%;
      }
    }

    @media #{$bootstrap-break-xs} {
      svg {
        font-size: 240%;
        margin-right: 5px;
      }

      h3 {
        font-size: 100%;
      }
    }
  }
}

.fp-small-grants {
  width: 900px;
  max-width: 100%;
  margin: 30px auto;

  @media #{$bootstrap-break-sm} {
    margin-top: 0;
  }
}

.fp-promo-block {
  margin: 0 30px;
  text-align: center;

  img {
    max-width: 100%;
    width: 400px;
    margin-bottom: 15px;

    &:first-of-type {
      margin-right: 20px;

      @media #{$bootstrap-break-lg} {
        margin-right: 0 !important;
      }
    }
  }
}